<template>
  <Checkout></Checkout>
</template>

<script>
import Checkout from "./Checkout";
export default {
  name: "ClinicCheckout",
  components: { Checkout },
};
</script>

<style scoped>
</style>
